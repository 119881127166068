@import './colors.css';
@import './typography.css';

html, body {
    height: 100%;
    font-family: Light, sans-serif;
}

a {
    text-decoration: none;
    color: var(--demos-orange);
    font-weight: 600;
}
h3 {
    color: var(--demos-orange);
    margin-bottom: 1rem;
}

.orange {
    color: var(--demos-orange)
}

p .orange {
    font-weight: 600;
}

.outbutton {
    padding: 5px;
    background-color: var(--demos-orange);
    width: 224px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1rem;
}

.outbutton a{
    color: white;
}

/* ------- Landing page ------- */

/* This it he parent element for absolutely positioning the nav */
#backdrop, #article{
    background-image: url('../assets/smile.jpeg');
    height: 100vh;
    position: relative;
    background-size: 100%;
}

#mainpage-container {
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(29, 50, 53, 1) 70%);
    position: relative;
    min-height: 650px;
}

.image-wrapper {
    margin: 100px;
}

.demos-logo {
    width: 220px;
}

#titles {
    width: 680px;
    position: absolute;
    bottom: 50px;
    margin: 100px;
}

.demos-o {
    color: var(--demos-orange);
    font-family: Book, sans-serif;
}

h1 {
    font-size: 6em;
    font-family: Black, sans-serif;
    color: white;
    line-height: 1em;
    overflow: hidden;
}

h2 {
    font-size: 2.5em;
    font-family: Light, sans-serif;
    color: white;
    font-weight: 400;
}

/* ------- Article page ------- */
.text-wrapper{
    background: rgb(245, 244, 244);
    position: relative;
    margin-top: 80px;
    margin-right: 400px;
    float: right;
    min-height: calc(100vh - 80px);
    padding: 40px 50px 100px 50px;
    max-width: 960px;
}

#page-title h1{
    font-size: 1.5em;
    text-transform: uppercase;
}

p, li{
    font-family: Light, sans-serif;
    font-size: 1.2rem;
}

.text-wrapper h2 {
    color: black;
}

.text p, .text ol{
    margin-bottom: 1rem;
}

.text ol{
    padding: revert;
}

.text li{
    display: list-item;
    overflow-x: visible;
}
/* ------- Header / Footer ------- */
footer, header {
    height: 80px;
    background-color: var(--demos-orange);
    width: 100%;
    position: fixed;
    z-index: 4;
}

footer {
    bottom: 0;
}

header {
    top: 0;
}

header .demos-logo {
    padding: 23px;
    position: fixed;
    right: 0;
}
#page-title {
    position: absolute;
    width: 710px;
    margin-left: 50px;
    padding-top: 16px;
}

/* ------- Nav Layout ------- */
.main-wrapper {
    position: relative;
}

.lozenge {
    background-color: var(--demos-orange);
    border-radius: 11px;
    width: 200px;
    height: 0.5em;
    margin: 1em 0 3em 0; 
}

.nav {
    width: 400px;
    padding: 40px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    /*height: calc(100vh - 80px);*/
    min-height: 720px;
    overflow: hidden;
}

.link, .section-head {
    font-family: Black;
    font-weight: 500;
    color: var(--demos-dark);
    text-decoration: none;
    display: inline-block;
    position: relative;
    word-wrap: nowrap;
    font-size: 1.8rem;
}

#article .nav {
    margin-top: 60px;
    /*height: calc(100vh - 140px);*/
}

.nav > ul > li > a {
    text-decoration: none;
}


/* ------- Breaks ------- */
@media screen and (max-width: 1200px) {

    
    #titles {
        width: 500px;
    }
    
    #titles h1{
        font-size: 4rem;
    }
    #titles h2 {
        font-size: 2rem;
    }

}


/* ------- Break 2: 1200px ------- */
@media screen and (max-width: 1050px) {
    .nav {
        width: 300px;
    }
    .text-wrapper {
        margin-right: 300px;
    }

    .link {
        font-size: 1.2rem;
    }

    #titles {
        width: 250px;
    }
    
    #titles h1{
        font-size: 3rem;
    }
    #titles h2 {
        font-size: 1.8em;
    }
    #page-title {
        width: 400px;
        padding-top: 12px;
    }
    #page-title h1 {
        font-size: 1.2em;
    }
}

/* ------- Mobile break ------- */
@media screen and (max-width: 700px) {
    #page-title {
        display: none;
    }

    .text-wrapper {
        width: 100%;
        margin-right: 0
    }

    .image-wrapper, #titles {
        width: 100%;
        min-width: 350px;
        position: relative;
        margin: 0;
        padding: 50px;
    }

    .nav, #article .nav {
        width: 100%;
        min-width: 350px;
        position: relative;
        padding-left: 50px;
        height: auto;
        min-height: 0;
    }

    #mainpage-container {
        height: auto;
        min-height: 0;
        background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(29, 50, 53, 1) 90%);
    }

    footer {
        height: 40px;
    }

    header .demos-logo {
        left: 25px;
    }
}
